/* SYNCFUSION */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-notifications/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';

/*calendar css*/
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';

/* FONTAWESOME */
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';

body {
  font-size: 14px !important;
  font-family: Roboto, "Helvetica Neue", sans-serif, "Segoe UI", "GeezaPro", "DejaVu Serif", "-apple-system", "BlinkMacSystemFont" !important;
  overflow-x: hidden;
  background-color: white;
  margin: 0;
}

.e-grid .e-headercelldiv {
  font-weight: bold
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -300px;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }

.sidebar-title {
  font-size: 1.5rem;
  font-weight: 500;
}

.sidebar-title-text {
  line-height: 50px;
  height: 50px;
  text-align: center;
}

.sidebar-menu {
  width: 300px;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#topbar {
  display: block;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -300px;
  }

  #topbar {
    display: none;
  }
}

html, body { height: 100%; }

.btn-text {
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  text-transform: capitalize;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
input:-webkit-autofill::selection {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

/* standard font size site-wide */
p, a, li, th, td, label, label.col-form-label,
.e-grid, .e-grid.e-control, .e-grid.e-rowcell, 
a.nav-link, div.alert, div.card-header,
a.e-numericitem, span.e-constant, span.e-label,
span.text-muted, label > span.e-label, .e-radio + label .e-label,
.e-checkbox-wrapper .e-label, .e-pagercontainer,
td.e-rowcell, td.e-rowcell.e-templatecell, tr.e-row > td.e-rowcell,
.e-outline label.e-float-text, .e-outline.e-float-input label.e-float-text, 
.e-outline.e-float-input.e-control-wrapper label.e-float-text, 
.e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, 
.e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
.e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper,
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper,
.e-dropdownbase .e-list-item, .e-dropdownbase .e-list-item.e-item-focus,
.accordion-title, .e-accordion .e-acrdn-item .e-acrdn-panel {
  font-size: 12px;
}

/* title font size site-wide */
/* includes syncfusion compone headers such as accordion */
.e-acrdn-header > div.e-acrdn-header-content
button.btn,
button.btn-secondary,
button.btn-text, 
.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-size: 14px;
}

/* overriding auto-capitalized tab titles */
.e-tab .e-tab-header .e-toolbar-item .e-tab-text,
div.e-tab-wrap > div.e-text-wrap > div.e-tab-text {
  font-size: 12px;
  text-transform: capitalize;
  color: #000;
}

